import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { CardDS, ButtonDS } from '../../'
import { useSelector } from 'react-redux'
import { VerticalIH } from '../../../interfaces'
import { StoreType } from '../../../../api/types/store'
import { tokens } from '@JOTAJornalismo/jota-design-system'
import { getLastProcontent } from '@App/api/api'
import { JU_TOKEN } from '@App/libs/utils/constants/global'
import { AUTH_CONSTANT } from '@App/libs/utils/constants'
import { returnProLinkByVertical } from '@App/libs/utils/geral'
import { makeProURL } from '../first-scroll/JotaPRO.component'
import { checkSpecialOrInfoNews } from '@App/libs/utils/constants/route'
import { PATROCINADO } from '@App/libs/utils/constants/home'
import { CONTEUDO_LIBERADO } from '../first-scroll/MiddleColumn.component'
import router from 'next/router'
import { VerticalName, VerticalType } from '@App/libs/interfaces/PRO.interfaces'
import { getColorVertical, isContentPro, isUserPro } from '@App/libs/utils/pro'

const { fonts, colors } = tokens

export const getColorHex = (verticalTemp: VerticalType) => {
    switch (verticalTemp) {
        case VerticalName.PODER:
            return colors.blue.jotaBlueDark
        case VerticalName.TRIBUTOS:
            return colors.green.jotaGreenDark
        case VerticalName.SAUDE:
            return colors.purple.jotaPurple
        case VerticalName.ENERGIA:
            return colors.red.jotaRed
        case VerticalName.TRABALHISTA:
            return colors.gray.jotaGrayDarker
    }
}

export const getColorString = (verticalTemp: VerticalType) => {
    switch (verticalTemp) {
        case VerticalName.PODER:
            return 'blue'
        case VerticalName.TRIBUTOS:
            return 'green'
        case VerticalName.SAUDE:
            return 'purple'
        case VerticalName.TRABALHISTA:
            return 'gray'
    }
}

const Vertical: FC<{
    news: VerticalIH
    vertical: VerticalType
}> = ({ news, vertical }): React.JSX.Element => {
    const { auth } = useSelector((state: StoreType) => state)
    const [userPro, setUserPro] = useState<boolean>(false)
    const [proContent, setProContent] = useState<any[]>([])

    useEffect(() => {
        const userBO: any = auth.user?.getUserBO()
        if (
            (userBO?.profile?.has_poder &&
                localStorage.getItem(JU_TOKEN) !== null) ||
            (userBO?.profile?.has_tributos &&
                localStorage.getItem(JU_TOKEN) !== null)
        ) {
            setUserPro(true)
        }
    }, [auth])

    useEffect(() => {
        if (
            vertical == VerticalName.PODER ||
            vertical == VerticalName.TRIBUTOS ||
            vertical == VerticalName.SAUDE
        ) {
            getLastProcontent(vertical.toLowerCase(), 2).then((res: any) => {
                res.map((item: any) =>
                    setProContent((old) => [...old, item._source])
                )
            })
        }
    }, [vertical])

    const getDate = (value: string) => {
        const d = new Date(value)
        return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    }

    const getTime = (value: string) => {
        const d = new Date(value)

        return `${String(d.getHours()).padStart(2, '0')}:${String(
            d.getMinutes()
        ).padStart(2, '0')}`
    }

    return (
        <Container>
            <Title vertical={vertical}>{vertical}</Title>
            <CardsContainer>
                {news?.content.map((newsItem, index) => (
                    <CardDS
                        key={newsItem.id}
                        title={newsItem.title}
                        titleSearchTerm={''}
                        newsLink={
                            newsItem.type === 'pro'
                                ? `${returnProLinkByVertical(
                                      newsItem.id,
                                      vertical
                                  )}`
                                : `${checkSpecialOrInfoNews(
                                      newsItem.categories,
                                      newsItem.slug,
                                      newsItem.permalink
                                  )}`
                        }
                        size='small'
                        tagLabel={newsItem.hat}
                        tagColor={getColorVertical(vertical)}
                        tagDisableHover
                        relatedTagLabel={newsItem.category.name}
                        relatedTagDisableHover
                        type={newsItem.type === 'pro' ? 'elevation' : 'normal'}
                        isContentPro={newsItem.type === 'pro' && !userPro}
                        isUserPro={
                            newsItem.type === 'pro' && userPro ? userPro : false
                        }
                        hasSeparator={
                            newsItem.type !== 'pro' &&
                            index !==
                                news.content.indexOf(
                                    news.content[news.content.length - 1]
                                )
                        }
                        authorTagLabel={
                            newsItem.authors &&
                            Array.isArray(newsItem.authors) &&
                            newsItem.authors[0]?.name
                        }
                        multipleAuthors={
                            Array.isArray(newsItem.authors)
                                ? newsItem.authors
                                : []
                        }
                        multipleAuthorsClickEvent={(authorUrl) => {
                            if (authorUrl) {
                                return router.push(`autor/${authorUrl}`)
                            }
                        }}
                        subjectTagLabel={
                            newsItem.inherits_from_PRO
                                ? CONTEUDO_LIBERADO
                                : undefined
                        }
                        subjectTagDisableHover
                        isSponsored={
                            (newsItem.type === PATROCINADO ||
                                newsItem.inherits_from_PRO) ??
                            false
                        }
                    />
                ))}

                {proContent &&
                    proContent.length > 1 &&
                    proContent.map((itemPro) => {
                        return (
                            <CardDS
                                key={itemPro.source.id}
                                title={itemPro.title.headline}
                                titleSearchTerm={''}
                                newsLink={makeProURL(
                                    itemPro.vertical.slug,
                                    itemPro.categories[0].slug,
                                    itemPro.source.slug
                                )}
                                size='small'
                                tagLabel={itemPro.categories[0].name}
                                tagColor={getColorVertical(vertical)}
                                tagDisableHover
                                relatedTagLabel={getTime(
                                    itemPro.source.published_at
                                )}
                                relatedTagDisableHover
                                type={'elevation'}
                                isContentPro={isContentPro(vertical, auth)}
                                isUserPro={isUserPro(vertical, auth)}
                                hasSeparator={false}
                                authorTagLabel={getDate(
                                    itemPro.source.published_at
                                )}
                                authorTagDisableHover
                            />
                        )
                    })}
            </CardsContainer>
            <ButtonContainer>
                {auth?.authState != AUTH_CONSTANT.AUTH_STATE.USER_LOGGED ? (
                    <ButtonDS
                        id={`jota-pro-${vertical}`}
                        label={`Conheça o JOTA PRO ${vertical}`}
                        size='small'
                        color={getColorString(vertical)}
                        type='filled'
                        onClick={() => {
                            window.open(news.link, '_blank')
                        }}
                    />
                ) : (
                    <ButtonDS
                        label={`Mais conteúdos de ${vertical}`}
                        size='small'
                        color={getColorString(vertical)}
                        type='filled'
                        onClick={() => {
                            window.open(
                                `/${vertical
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')}`,
                                '_self'
                            )
                        }}
                        LastIcon='jotaMaterialArrow'
                        fillIcone='jotaWhite'
                        insideType='right'
                    />
                )}
            </ButtonContainer>
        </Container>
    )
}

export default Vertical

const Container = styled.div`
    @media only screen and (max-width: 499px) {
        width: 100%;
    }

    @media only screen and (min-width: 500px) and (max-width: 1199px) {
        width: 100%;
    }
`

const Title = styled.h4<{ vertical: VerticalType }>`
    ${fonts.jotaHeading05()};
    margin: 0;
    color: ${(props) => getColorHex(props.vertical)};
`

const CardsContainer = styled.ul`
    display: grid;
    gap: 24px;
    padding: 0;
    margin: 24px 0;

    @media only screen and (min-width: 500px) and (max-width: 1199px) {
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        width: 100%;
    }

    h2 > span {
        display: none;
    }
`

export const VerticaisHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 44px 24px 44px;

    @media only screen and (max-width: 500px) {
        padding: 0 16px 24px 16px;
    }

    @media only screen and (min-width: 500px) and (max-width: 1199px) {
        padding: 0 36px 24px 36px;
    }
`

export const VerticaisTitle = styled.h4`
    ${fonts.jotaHeading04()};
    color: ${colors.black.jotaBlack};
    margin: 0;
    padding: 0;
`

export const VerticaisDesc = styled.p`
    ${fonts.jotaBodyLarge()};
    color: ${colors.gray.jotaGrayDark};
    margin: 0;
    padding: 0;
    padding-bottom: 8px;
    border-bottom: 1px solid ${colors.transparent.jotaTGrayLight40};

    & > a {
        text-decoration: underline;
        color: ${colors.black.jotaBlack};
        font-weight: 700;
    }
`

const ButtonContainer = styled.div`
    & button {
        @media only screen and (max-width: 700px) {
            width: 100%;
        }
    }
`
